@use 'global' as *;
// ---------------------------------------------------------

.filter_grid_container {
  @include container;

  display: grid;
  padding-top: 60px;

  @include media($medium-lowest) {
    gap: 40px;
    grid-template-columns: 1fr 5fr;
  }
}

.header {
  padding-top: 80px;
  text-align: center;
}

// ---------------------------------------------------------

.card_items {
  margin-bottom: 40px;

  > div:first-of-type {
    display: grid;
    gap: 30px;

    @include media($small-lowest) {
      grid-template-columns: 1fr 1fr;
    }

    @include media($large-lowest) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}

// ---------------------------------------------------------

.filters_container {
  margin-bottom: 40px;
  min-width: 280px;

  @include media($medium-lowest) {
    margin-bottom: 0;
  }
}

// ---------------------------------------------------------

.filter_container {
  @include reset-list;

  display: none;

  @include media($medium-lowest) {
    display: block;
    margin-top: 10px;
  }

  li {
    margin-bottom: 0;
  }
}

.filter_container_smaller_screens {
  @include media($medium-lowest) {
    display: none;
  }
}

.filter_item {
  &_is_active {
    font-family: var(--font-family-base-bold);
    position: relative;

    svg {
      left: 8px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 10px;
    }
  }

  a {
    color: var(--color-off-black);
    display: flex;
    font-size: 18px;
    line-height: 1.3;
    padding: 3px 20px 4px 28px;

    &:hover {
      background-color: var(--color-cloud);
      transition: var(--animation-timing);
    }
  }
}

// ---------------------------------------------------------

.accordion_item {
  margin-bottom: 20px;

  @include media($medium-lowest) {
    margin-bottom: 40px;
  }
}

.accordion_item_button {
  align-items: center;
  border-bottom: 1px solid var(--color-prime-gray);
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  text-transform: capitalize;

  &:hover {
    color: var(--color-slate);
  }

  @include media($medium-lowest) {
    border-bottom: 1px solid var(--color-slate);
  }

  span {
    padding-bottom: 0;

    @include h5;

    @include media($medium-lowest) {
      padding-bottom: 8px;
    }
  }

  svg {
    transform: rotate(0deg);
    transition: transform var(--animation-timing);
    width: 6px;
  }

  &[aria-expanded='true'] {
    svg {
      transform: rotate(90deg);
    }
  }
}

.accordion_item_panel {
  display: block !important;
  max-height: 0;
  transition: max-height 100ms;

  @include media($medium-lowest) {
    overflow: hidden;
  }

  .filter_select {
    opacity: 0%;
    transition: opacity 100ms;

    > div {
      // react-select wrapper
      border-color: var(--color-prime-gray);

      span {
        // react-select spacer
        background-color: transparent;
      }

      div[role='button'] {
        // react-select label button "x"
        color: var(--color-off-black);

        @include hover {
          color: var(--color-saturated-red);
        }

        > svg {
          height: 18px;
          width: 18px;
        }
      }

      > div > div {
        color: var(--color-off-black);
        cursor: pointer;

        // react-select svg
      }

      > div > div > div {
        // react-select label
        background-color: var(--color-cloud);
        font-size: 1.2rem;
      }
    }
  }

  &[aria-hidden='false'] {
    max-height: 100%;

    .filter_select {
      opacity: 100%;
    }
  }
}

// ---------------------------------------------------------

.no_results_message,
.load_more_button {
  display: flex;
  justify-content: center;
}

.load_more_button {
  margin-top: 2rem;
}

// ---------------------------------------------------------

.button_clear_filters {
  @include link;

  align-items: center;
  display: flex;
  justify-content: center;
  text-decoration: none;

  .close_icon {
    height: 25px;
    width: 15px;

    svg {
      height: 12px;
      margin-left: 5px;
      width: 12px;
    }
  }
}

.filters_header {
  align-items: center;
  border-bottom: 1px solid var(--color-prime-gray);
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;

  span {
    @include h6;
  }

  @include media($medium-lowest) {
    border-bottom: none;
  }
}

.search_input {
  align-items: flex-start;
  display: flex;
  height: 45px;
  margin-bottom: 16px;
  min-width: 200px;
  width: calc(100%);

  input {
    background-color: var(--color-cloud);
    border-style: none;
    border-width: 0;
    font-family: var(--font-family-headings);
    font-size: 16px;
    margin-bottom: 16px;
    padding: 10px 16px;
    width: calc(100% - 43px);
  }

  button {
    border-radius: 0;
    cursor: pointer;
    height: 41px;
    position: relative;

    svg {
      height: 17px;
      left: 12px;
      position: absolute;
      top: 10px;
      width: 17px;
    }
  }
}
